import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Sustainability-Forms-Admin';
  get isEmbedded() {
    return window.parent !== window;
  }
}
