<!-- eslint-disable @angular-eslint/template/no-negated-async -->
<div>
  <div class="grid">
    <div class="col-8 pl-4">
      <p-card class="dashboard-card">
        <ng-container
          *ngIf="!(chartLoading$ | async); else spinner"
          [ngTemplateOutlet]="totalSurveysChart"
        ></ng-container>
      </p-card>
    </div>
    <div class="col-4 pr-4">
      <p-card class="dashboard-card">
        <ng-container
          *ngIf="!(deadlineLoading$ | async); else spinner"
          [ngTemplateOutlet]="deadline"
        ></ng-container>
      </p-card>
    </div>
  </div>
  <div class="grid mt-5">
    <div class="col pl-4">
      <app-submission-table></app-submission-table>
    </div>
  </div>
</div>

<ng-template #spinner>
  <div class="flex justify-content-center" style="height: 100vh">
    <p-progressSpinner></p-progressSpinner>
  </div>
</ng-template>

<ng-template #deadline>
  <div class="deadline flex-column justify-content-center">
    <div class="grid">
      <div class="col-12 label flex align-items-center pt-4">
        <i class="pi pi-clock mr-2" style="font-size: 1.25rem"></i>
        <span >{{ deadlineHeader }}</span>
      </div>
    </div>
    <div class="grid mt-3" [ngClass]="{ 'mb-3': !isDeadlineEnded }">
      <div
        class="col-12 date"
        [ngClass]="{ 'deadline-ended': isDeadlineEnded }"
      >
        <span>{{ deadlineDate | date : "dd MMMM YYYY" }}</span>
      </div>
    </div>
    <div *ngIf="isDeadlineEnded">
      <div class="grid mb-3 deadline-ended">
        <div class="col-12">
          <span>{{ exceedDeadlineMessage }}</span>
        </div>
      </div>
      <div class="grid">
        <div class="col-12 label not-submitted-message">
          <span
            ><span class="deadline-ended" style="text-decoration: underline">{{
              notSubmitted
            }}</span>
            {{ notSubmittedMessage }}</span
          >
        </div>
      </div>
    </div>
    <div class="grid" *ngIf="!isDeadlineEnded">
      <div class="col-2">
        <div class="grid flex-column">
          <div class="col-1">
            <span class="timer">{{ remainingDays }}</span>
          </div>
          <div class="col-1">
            <span class="time-label">Days</span>
          </div>
        </div>
      </div>
      <div class="col-1">
        <span class="timer">:</span>
      </div>
      <div class="col-2">
        <div class="grid flex-column">
          <div class="col-1">
            <span class="timer">{{ remainingHours }}</span>
          </div>
          <div class="col-1">
            <span class="time-label">Hours</span>
          </div>
        </div>
      </div>
      <div class="col-1 ml-4">
        <span class="timer">:</span>
      </div>
      <div class="col-2">
        <div class="grid flex-column">
          <div class="col-1">
            <span class="timer">{{ remainingMinutes }}</span>
          </div>
          <div class="col-1">
            <span class="time-label">Minutes</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #totalSurveysChart>
  <div class="flex justify-content-start">RR Form Status</div>
  <div class="grid align-items-center">
    <p-chart
      class="col-5"
      style="padding-bottom: 7rem"
      #chart
      type="doughnut"
      [height]="'0'"
      [data]="totalSurveys"
      [plugins]="totalValuePlugin()"
      [options]="options"
    ></p-chart>
    <div class="col-7 flex align-items-center">
      <ul class="grid mt-6">
        <li
          *ngFor="let label of totalSurveys.labels; let i = index"
          class="list-items"
        >
          <div class="flex">
            <div
              class="dot mt-1 mr-2"
              [ngStyle]="{
                'background-color': totalSurveys.datasets[0].backgroundColor[i]
              }"
            ></div>
            <span class="label">{{ label }}</span>
          </div>

          <div class="count">
            <span>{{ totalSurveys.datasets[0].data[i] }}</span>
            <span
              class="percentage"
              [ngStyle]="{ color: totalSurveys.datasets[0].backgroundColor[i] }"
            >
              ({{
                getValuePercentage(
                  totalSurveys.datasets[0].data[i],
                  totalSurveys.datasets[0].data
                )
              }})</span
            >
          </div>
        </li>
      </ul>
    </div>
  </div>
</ng-template>
