import { Injectable } from '@angular/core';
import { StateService } from './state.service';
import {
  INITIAL_QUESTIONNAIRE_STATE,
  Questionnaire,
  QuestionnaireState,
} from 'src/app/interface/questionnaire-state.interface';
import { FormService } from '../data-service/forms.service';
import { EmailPayloadService } from '../data-service/email-payload.service';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireStateService extends StateService<QuestionnaireState> {
  questionnaire$ = this.select((state) => state.questionnaire);
  questionnaires$ = this.select((state) => state.questionnaireList);
  ownerOrgId: number;

  constructor(
    private formService: FormService,
    private emailPayloadService: EmailPayloadService
  ) {
    super(INITIAL_QUESTIONNAIRE_STATE);
  }

  getQuestionnaire(index = 0, orgId: number) {
    this.formService
      .getQuestionnaireId(orgId)
      .subscribe((res: Questionnaire[]) => {
        if (res.length) {
          this.emailPayloadService.formName = res[index].title;
          this.emailPayloadService.formDeadline = res[index].end;
          this.emailPayloadService.formId = res[index].formId;
          this.ownerOrgId = res[0].ownerOrgId;
          this.setState({ questionnaireList: res });
          this.setState({ questionnaire: res[index] });

          return;
        }

        this.setState({ questionnaireList: [] });
      });
  }
}
