<ng-container [ngSwitch]="step">
  <div class="grid modal flex-column">
    <ng-container *ngSwitchCase="1">
      <ng-container [ngTemplateOutlet]="step1"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="2">
      <ng-container [ngTemplateOutlet]="step2"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="3">
      <ng-container [ngTemplateOutlet]="step3"></ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-template #step1>
  <div>
    <div class="col flex justify-content-center modal-header">
      Broadcast Reminder
    </div>
    <div class="col mb-3 flex justify-content-center">
      *Note that reminder will be sent as an email to your suppliers
    </div>
    <div class="col mb-3 flex justify-content-start">
      Not Submitted Recipients:
      {{ selectedRecipients.length }} / {{ recipients.length }}
    </div>
    <div class="col">
      <div class="grid p-0">
        <div class="col-12 pt-0">
          <p-table [value]="recipients" [(selection)]="selectedRecipients">
            <ng-template pTemplate="body" let-recipient>
              <tr>
                <td>
                  <p-checkbox
                    name="files"
                    [value]="recipient"
                    [(ngModel)]="selectedRecipients"
                  ></p-checkbox>
                </td>
                <td>{{ recipient.companyName }}</td>
                <td class="email-cell">
                  {{ recipient.email }}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
      <div class="col flex justify-content-end">
        <div class="grid my-3">
          <div class="col flex justify-content-end">
            <p-button
              class="mx-3"
              (onClick)="onClose()"
              styleClass="p-button-outlined"
              >Cancel</p-button
            >
            <div class="grid">
              <div class="col">
                <p-button
                  (onClick)="onNextStep()"
                  [disabled]="!selectedRecipients.length"
                  >Next</p-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #step2>
  <p-progressSpinner
    *ngIf="loadingEmailSending"
    class="flex justify-content-center"
  ></p-progressSpinner>

  <div [formGroup]="emailForm">
    <div class="col flex justify-content-start modal-header">
      <span class="back" (click)="onBack()">
        <i class="pi pi-chevron-left mr-3"></i
        >{{ selectedRecipients.length }} Recipients</span
      >
    </div>
    <div class="col mb-3 flex flex-column">
      <label htmlFor="subject" class="mb-2">Subject:</label>
      <input formControlName="subject" pInputText id="subject" />
      <ng-container *ngIf="subject.errors">
        <ng-container
          *ngTemplateOutlet="errorMessage; context: { errors: subject.errors }"
        ></ng-container>
      </ng-container>
    </div>
    <div class="col mb-3 flex flex-column">
      <label htmlFor="username" class="mb-2"
        >Write a message to remind your suppliers:</label
      >
      <textarea
        formControlName="message"
        style="resize: none; color: black"
        rows="5"
        cols="30"
        pInputTextarea
      ></textarea>
      <ng-container *ngIf="message.errors">
        <ng-container
          *ngTemplateOutlet="errorMessage; context: { errors: message.errors }"
        ></ng-container>
      </ng-container>
    </div>

    <div class="col">
      <div class="grid my-3">
        <div class="col flex justify-content-end">
          <p-button
            class="mx-3"
            (onClick)="onClose()"
            styleClass="p-button-outlined"
            [disabled]="loadingEmailSending"
            >Cancel</p-button
          >
          <div class="grid flex-column">
            <div class="col">
              <p-button
                (onClick)="onNextStep(true)"
                [disabled]="emailForm.invalid || loadingEmailSending"
                >Send Reminder</p-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #step3>
  <div class="col flex text-center modal-header">
    You have successfully broadcasted your reminder to all your suppliers.
  </div>
  <div class="col flex justify-content-center">
    <p-button (onClick)="onClose()">Done</p-button>
  </div>
  <div class="col mb-1 text-base font-semibold">
    <label htmlFor="from" class="mb-2">Reminder Details:</label>
  </div>
  <div class="col text-xs font-bold">
    <label htmlFor="subject" class="mb-2">Subject:</label>
  </div>
  <div class="col text-sm">
    <label htmlFor="subject" class="mb-2">{{ subject.value }}</label>
  </div>
  <div class="col text-xs font-bold">
    <label htmlFor="subject" class="mb-2"
      >Recipients: {{ selectedRecipients.length }}</label
    >
  </div>
  <div class="col text-sm">
    <p-card class="pt-0 recipients-list-card">
      <ng-container *ngFor="let recipient of selectedRecipients; last as last">
        <span
          >{{ recipient.email }}<ng-container *ngIf="!last">,</ng-container>
          <br
        /></span>
      </ng-container>
    </p-card>
  </div>
  <div class="col text-xs font-bold">
    <label htmlFor="subject" class="mb-2">Message:</label>
  </div>
  <div class="col text-sm">
    <label htmlFor="subject" class="mb-2">{{ message.value }}</label>
  </div>
  <div class="col text-xs font-bold">
    <label htmlFor="subject" class="mb-2">Sent Date:</label>
  </div>
  <div class="col text-sm">
    <label htmlFor="subject" class="mb-2">{{
      sentDate.value | date : "MM/dd/YYYY"
    }}</label>
  </div>
</ng-template>

<ng-template #errorMessage let-errors="errors">
  <small class="p-error block">
    <ng-container *ngIf="errors?.required">
      This field is required
    </ng-container>
    <ng-container *ngIf="errors?.maxlength">
      Max length of {{ errors?.maxlength?.requiredLength }} exceeded
    </ng-container>
  </small>
</ng-template>
