import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EventStateService } from '../state-service/event-state.service';
import { EmailPayload } from 'src/app/models/email-payload.model';
import { ddmmyyyy, mmddyyyy } from 'src/app/constants/date-format.const';
import { Status } from 'src/app/enums/status.enum';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor(
    private firestore: AngularFirestore,
    private datePipe: DatePipe,
    private eventStateService: EventStateService,
    private httpClient: HttpClient
  ) {}

  getQuestionnaireId(orgId: number) {
    this.eventStateService.loading = true;
    return this.firestore
      .collection('/rrforms/submissions/questionairreid', (ref) =>
        ref.where('ownerOrgId', '==', orgId)
      )
      .valueChanges()
      .pipe(
        map((res) => {
          const responseToArray = Object.keys(res).map((key) => res[key]);

          this.eventStateService.loading = false;
          return responseToArray.map((data) => ({
            ...data,
            end: this.transformDate(data.end, mmddyyyy),
            start: this.transformDate(data.start, mmddyyyy),
          }));
        })
      );
  }

  getDocuments(formId: string) {
    this.eventStateService.loading = true;
    return this.firestore
      .collection('/rrforms/submissions/documents', (ref) =>
        ref.where('formId', '==', formId).orderBy('createdOn', 'asc')
      )
      .valueChanges()
      .pipe(
        map((res) => {
          const responseToArray = Object.keys(res).map((key) => res[key]);
          this.eventStateService.loading = false;
          return responseToArray.map((data) => ({
            ...data,
            status:
              data.status === 'COMPLETED'
                ? Status.Submitted
                : Status.NotSubmitted,

            createdOn: data.createdOn.toDate(),
            modifiedOn: data.modifiedOn.toDate(),
          }));
        })
      );
  }

  downloadPdfSubmission(submissionId: string) {
    return this.httpClient.get(
      `${environment.ARP_FORMS}download/pdf-submission/?id=${submissionId}`,
      { responseType: 'blob' }
    );
  }

  downloadExcelReport(formId: string) {
    return this.httpClient.get(
      `${environment.ARP_FORMS}download/excel-report/?form_id=${formId}`,
      { responseType: 'blob' }
    );
  }

  sendEmailReminder(emailPayload: EmailPayload) {
    return this.httpClient.post(
      `${environment.ARP_FORMS}reminder/email/`,
      emailPayload
    );
  }

  getPdfReportUrl(formId: string, reportId: string) {
    return this.httpClient.get(
      `${environment.ARP_FORMS}pdf-report-url/?form_id=${formId}&report_id=${reportId}`
    );
  }

  private transformDate(date, format = ddmmyyyy) {
    return this.datePipe.transform(date.toDate(), format);
  }
}
