<!-- eslint-disable @angular-eslint/template/no-negated-async -->
<div class="grid">
  <div class="col ml-4" [class.spinner-container]="loading$ | async">
    <p-progressSpinner
      class="flex justify-content-center"
      *ngIf="loading$ | async"
    ></p-progressSpinner>
    <p-tabView
      *ngIf="(questionnaires$ | async)?.length > 0 && !(loading$ | async)"
      class="gspnr-tabs"
      (onChange)="tabChanged($event)"
    >
      <ng-container *ngFor="let tab of tabs; index as i">
        <p-tabPanel [header]="tab.name">
          <div class="mt-3" [ngSwitch]="i">
            <div *ngSwitchCase="0">
              <app-rr-submission-dashboard></app-rr-submission-dashboard>
            </div>
            <div *ngSwitchCase="1">
              <app-results-overview
                *ngIf="loadResultsOverview"
              ></app-results-overview>
            </div>
          </div>
        </p-tabPanel>
      </ng-container>
    </p-tabView>
    <app-no-records-page
      *ngIf="(questionnaires$ | async)?.length <= 0 && !(loading$ | async)"
    ></app-no-records-page>
  </div>
</div>
