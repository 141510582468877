export const environment = {
  production: false,
  ARP_HOST: 'https://api-platform-testing.agridence.com/api/v1/',
  ARP_FORMS: 'https://api-forms-testing.agridence.com/v1/',
  firebase: {
    projectId: 'hcplatform-infra-internal-dev',
    appId: '1:813678965885:web:47b7b623ef3607a488ed14',
    storageBucket: 'hcplatform-infra-internal-dev.appspot.com',
    locationId: 'asia-southeast1',
    apiKey: 'AIzaSyAyTVDwgyyOpM50iJiY7sq5pckYUJmV0R4',
    authDomain: 'hcplatform-infra-internal-dev.firebaseapp.com',
    messagingSenderId: '813678965885',
    measurementId: 'G-M4PDC888GR',
  },
};
