import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventStateService {
  private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );

  get loading$() {
    return this._loading.asObservable();
  }

  set loading(value: boolean) {
    this._loading.next(value);
  }
}
