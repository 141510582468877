import { Injectable } from '@angular/core';
import { StateService } from './state.service';
import {
  DocumentsState,
  INITIAL_DOCUMENTS_STATE,
} from 'src/app/interface/documents-state.interface';
import { FormService } from '../data-service/forms.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentStateService extends StateService<DocumentsState> {
  documents$ = this.select((state) => state.documents);

  constructor(private formService: FormService) {
    super(INITIAL_DOCUMENTS_STATE);
  }

  getDocuments(formId: string) {
    this.formService
      .getDocuments(formId)
      .subscribe((res) => {
        this.setState({ documents: res });
      });
  }
}
