import { Injectable } from '@angular/core';
import { Report } from '../models/report.model';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  constructor() {}

  getReportId(reports: Report) {
    return JSON.stringify(Object.keys(reports)[0])?.replace(/"/g, '');
  }
}
