<div class="p-5" *ngIf="!(loading$ | async); else spinner">
  <div class="grid mb-3">
    <div class="col-12">
      <span style="font-size: 20px">Sustainability</span>
    </div>
  </div>

  <div class="grid">
    <div class="col-12">
      <p-tabView [activeIndex]="2">
        <ng-container *ngFor="let tab of tabs">
          <p-tabPanel [header]="tab.name">
            <div class="my-5" *ngIf="tab.name === 'GPSNR'; else notImplemented">
              <app-gpnsr-tab-content></app-gpnsr-tab-content>
            </div>
            <ng-template #notImplemented>
              <app-no-records-page></app-no-records-page>
            </ng-template>
          </p-tabPanel>
        </ng-container>
      </p-tabView>
    </div>
  </div>
</div>

<ng-template #spinner>
  <p-progressSpinner></p-progressSpinner>
</ng-template>
