import { SubmissionFile } from '../models/table-data.model';

export interface Document {
  createdOn: string;
  files: SubmissionFile[];
  formId: string;
  modifiedBy: string;
  modifiedOn: string;
  ownerOrgId: number;
  ownercompanyname: string;
  owneremail: string;
  recipientOrgId: number;
  recipientcompanyname: string;
  recipients: string[];
  session: string;
  status: string;
  stoken: string;
  submissionid: string;
  title: string;
}

export interface DocumentsState {
  documents: Document[];
}

export const INITIAL_DOCUMENTS_STATE: DocumentsState = {
  documents: [],
};
