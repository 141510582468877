import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RowData, SubmissionFile } from 'src/app/models/table-data.model';
import { QuestionnaireStateService } from 'src/app/services/state-service/questionnaire-state.service';
import { DownloadService } from 'src/app/services/download.service';

@Component({
  selector: 'app-download-attachment-modal',
  templateUrl: './download-attachment-modal.component.html',
  styleUrls: ['./download-attachment-modal.component.scss'],
})
export class DownloadAttachmentModalComponent implements OnInit {
  selectedSubmissions: RowData[] = [];
  files: SubmissionFile[] = [];
  selectedFiles: SubmissionFile[] = [];
  downloadProgress = 0;
  isDownloadStarted = false;
  isDownloadComplete = false;

  columns = [
    {
      field: 'name',
      displayName: 'Name',
    },
    {
      field: 'companyName',
      displayName: 'Company Name',
    },
    {
      field: 'size',
      displayName: 'Size',
    },
  ];

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private questionnaireStateService: QuestionnaireStateService,
    private downloadService: DownloadService
  ) {}

  ngOnInit() {
    this.initializeFileList();
  }

  initializeFileList() {
    const modifiedSubmissions = this.dialogConfig.data.map((data) => ({
      ...data,
      files: data.files.map((file) => ({
        ...file,
        companyName: data.recipientcompanyname,
      })),
    }));
    for (const submission of modifiedSubmissions) {
      this.files.push(...submission.files);
    }
  }

  onDownload() {
    this.dialogConfig.closable = false;
    this.dialogConfig.closeOnEscape = false;
    this.isDownloadStarted = true;
    this.isDownloadComplete = false;

    this.questionnaireStateService.questionnaire$.subscribe((res) => {
      this.downloadService
        .downloadAsZip(this.selectedFiles, '', res.title)
        .subscribe({
          next: (res) => {
            this.downloadProgress = res;
          },
          error: (err) => {
            // eslint-disable-next-line no-console
            console.log(err);
            this.onClose();
          },
          complete: () => {
            this.isDownloadComplete = true;
            this.dialogConfig.closable = true;
            this.dialogConfig.closeOnEscape = true;
            this.isDownloadStarted = false;
            this.onClose();
          },
        });
    });
  }

  onClose() {
    this.dialogRef.close();
  }
}
