import { Injectable } from '@angular/core';
import { EmailPayload } from 'src/app/models/email-payload.model';

@Injectable({
  providedIn: 'root',
})
export class EmailPayloadService {
  private _emailPayload: EmailPayload = new EmailPayload();

  get emailPayload() {
    return this._emailPayload;
  }

  set emails(emails: string[]) {
    this._emailPayload.emails = emails;
  }

  set ownerCompanyName(ownerCompanyName: string) {
    this._emailPayload.owner_company_name = ownerCompanyName;
  }

  set senderEmail(senderEmail: string) {
    this._emailPayload.sender_email = senderEmail;
  }

  set senderName(senderName: string) {
    this._emailPayload.sender_name = senderName;
  }

  set formName(formName: string) {
    this._emailPayload.form_name = formName;
  }

  set formId(formId: string) {
    this._emailPayload.form_id = formId;
  }

  set formDeadline(formDeadline: string) {
    this._emailPayload.form_deadline = formDeadline;
  }

  set subject(subject: string) {
    this._emailPayload.subject = subject;
  }

  set body(body: string) {
    this._emailPayload.body = body;
  }

  set logoURI(logoURI: string) {
    this._emailPayload.logo_uri = logoURI;
  }

  constructor() {}
}
