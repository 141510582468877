import { Component, OnInit } from '@angular/core';
import { QuestionnaireStateService } from '../services/state-service/questionnaire-state.service';
import { BehaviorSubject, Observable, skip } from 'rxjs';
import { Questionnaire } from '../interface/questionnaire-state.interface';

@Component({
  selector: 'app-gpnsr-tab-content',
  templateUrl: './gpnsr-tab-content.component.html',
  styleUrls: ['./gpnsr-tab-content.component.scss'],
})
export class GpnsrTabContentComponent implements OnInit {
  tabs = [
    {
      name: 'RR Submission Dashboard',
      data: {
        results: [],
      },
    },
    {
      name: 'Results Overview',
      data: {
        results: [],
      },
    },
  ];

  loadResultsOverview: boolean = false;
  questionnaires$ = new Observable<Questionnaire[]>();
  loading$ = new BehaviorSubject<boolean>(true);

  constructor(private questionnaireStateService: QuestionnaireStateService) {}

  ngOnInit(): void {
    this.questionnaires$ = this.questionnaireStateService.questionnaires$.pipe(
      skip(1)
    );

    this.questionnaires$.subscribe((res) => {
      this.loading$.next(false);
    });
  }

  tabChanged(event: any) {
    if (event.index === 1) {
      this.loadResultsOverview = true;
    }
  }
}
