import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EmailPayloadService } from 'src/app/services/data-service/email-payload.service';
import { FormService } from 'src/app/services/data-service/forms.service';

@Component({
  selector: 'app-broadcast-reminder-modal',
  templateUrl: './broadcast-reminder-modal.component.html',
  styleUrls: ['./broadcast-reminder-modal.component.scss'],
})
export class BroadcastReminderModalComponent implements OnInit {
  selectedRecipients: Recipient[] = [];
  recipients: Recipient[] = [];
  step = 1;

  emailForm: FormGroup;
  loadingEmailSending = false;

  get subject() {
    return this.emailForm.get('subject');
  }

  get message() {
    return this.emailForm.get('message');
  }

  get sentDate() {
    return this.emailForm.get('sentDate');
  }

  get messageTemplate() {
    return `This is a reminder to submit your ${this.emailPayload.form_name} to ${this.emailPayload.owner_company_name} by its deadline ${this.emailPayload.form_deadline}`;
  }

  get emailPayload() {
    return this.emailPayloadService.emailPayload;
  }

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private fb: FormBuilder,
    private emailPayloadService: EmailPayloadService,
    private formService: FormService
  ) {}

  ngOnInit(): void {
    this.initializeRecipientList();
    this.initializeEmailForm();
  }

  initializeEmailForm() {
    this.emailForm = this.fb.group({
      subject: [
        'Reminder to submit the form before its deadline',
        [Validators.required, Validators.maxLength(256)],
      ],
      message: ['', [Validators.required, Validators.maxLength(512)]],
      sentDate: [''],
    });
  }

  initializeRecipientList() {
    let id = 0;
    for (const selectedSubmission of this.dialogConfig.data) {
      for (const selectedRecipient of selectedSubmission.recipients) {
        const recipient: Recipient = {
          id: id++,
          companyName: selectedSubmission.recipientcompanyname,
          email: selectedRecipient,
        };
        this.recipients.push(recipient);
      }
    }

    this.selectedRecipients = [...this.recipients];
  }

  onClose() {
    this.dialogRef.close();
  }

  onNextStep(isSendReminder = false) {
    if (isSendReminder) {
      this.loadingEmailSending = true;
      this.emailPayloadService.subject = this.subject?.value;
      this.emailPayloadService.body = this.message?.value;
      this.formService.sendEmailReminder(this.emailPayload).subscribe({
        next: (res: any) => {
          if (res.emails.length) {
            this.sentDate.setValue(new Date());
            this.step++;
          }
        },
        error: () => {
          this.onClose();
        },
        complete: () => {
          this.loadingEmailSending = false;
        },
      });
    } else {
      this.step++;

      const selectedRecipientsEmails = this.selectedRecipients.map(
        (recipient) => recipient.email
      );

      this.emailPayloadService.emails = selectedRecipientsEmails;
      // this.emailPayloadService.emails = ['bryantt@weqollab.com'];
      this.message.setValue(this.messageTemplate);
    }
  }

  onBack() {
    this.step--;
  }
}

interface Recipient {
  id: number;
  companyName: string;
  email: string;
}
