export interface Questionnaire {
  end: string;
  formId: string;
  ownerOrgId: number;
  recipientcompanyname: string;
  start: string;
  title: string;
  reports: any;
  sheetsDataDocument: string;
}

export interface QuestionnaireState {
  questionnaire: Questionnaire;
  questionnaireList: Questionnaire[];
}

export const INITIAL_QUESTIONNAIRE_STATE: QuestionnaireState = {
  questionnaireList: [],
  questionnaire: {
    end: '',
    formId: '',
    ownerOrgId: 0,
    recipientcompanyname: '',
    start: '',
    title: '',
    reports: null,
    sheetsDataDocument: '',
  },
};
