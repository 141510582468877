import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';
import { BehaviorSubject, Observable } from 'rxjs';
import { SubmissionFile } from '../models/table-data.model';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  private _downloadProgress: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  private _downloadCompleted: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private _downloadStarted: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  get downloadProgress$() {
    return this._downloadProgress.asObservable();
  }
  set downloadProgress(value: number) {
    this._downloadProgress.next(value);
  }

  get downloadCompleted$() {
    return this._downloadCompleted.asObservable();
  }
  set downloadCompleted(value: boolean) {
    this._downloadCompleted.next(value);
  }

  get downloadStarted$() {
    return this._downloadStarted.asObservable();
  }
  set downloadStarted(value: boolean) {
    this._downloadStarted.next(value);
  }

  constructor() {}

  downloadAsZip(
    files: SubmissionFile[],
    fileName?: string,
    parentFileName?: string
  ) {
    return new Observable<number>((sub) => {
      const totalFiles = files.length;
      const zip = new JSZip();
      let totalDownloadedSize = 0;
      let totalSize = 0;
      let completedFiles = 0;
      let parentFolder;
      if (parentFileName) parentFolder = zip.folder(parentFileName);
      files.map(async (file) => {
        const response = await fetch(file.AssetURL);
        const fileSize = Number(response.headers.get('Content-Length'));
        const reader = response.body.getReader();
        totalSize += fileSize;
        const chunks = [];
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          chunks.push(value);
          totalDownloadedSize += value.length;
          sub.next(Math.floor((totalDownloadedSize / totalSize) * 100));
        }

        const blob = new Blob(chunks);
        if (parentFileName) {
          const folder = parentFolder.folder(file.companyName);
          folder.file(file.Name, blob);
        } else {
          zip.file(file.Name, blob);
        }

        completedFiles++;
        if (completedFiles === totalFiles) {
          await zip.generateAsync({ type: 'blob' }).then((content) => {
            saveAs(content, `${parentFileName || fileName} attachments.zip`);
            sub.complete();
          });
        }
      });
    });
  }
}
