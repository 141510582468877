<div class="flex flex-column align-items-center">
  <span> Download {{ questionnaire.title }} Report </span>
  <div class="py-5">
    <p-progressSpinner
      *ngIf="loading$ | async; else downloadDone"
    ></p-progressSpinner>
    <ng-template #downloadDone>
      <div class="pb-3 flex justify-content-center">
        <i
          class="pi pi-check-circle"
          style="font-size: 3.5rem; color: #22c55e"
        ></i>
      </div>
      <div>Report downloaded</div>
    </ng-template>
  </div>
  <div>
    <p-button (onClick)="onClose()" [disabled]="loading$ | async"
      >Close</p-button
    >
  </div>
</div>
