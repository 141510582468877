import { Component, OnInit } from '@angular/core';
import { QuestionnaireStateService } from '../services/state-service/questionnaire-state.service';
import { DocumentStateService } from '../services/state-service/documents-state.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { UserInfo } from '../models/user-info.model';
import { EmailPayloadService } from '../services/data-service/email-payload.service';

@Component({
  selector: 'app-sustainability-forms-admin-shell',
  templateUrl: './sustainability-forms-admin-shell.component.html',
  styleUrls: ['./sustainability-forms-admin-shell.component.scss'],
})
export class SustainabilityFormsAdminShellComponent implements OnInit {
  tabs = [
    {
      name: 'Dashboard',
      data: {
        results: [],
      },
    },
    {
      name: 'My Alerts',
      data: {
        results: [],
      },
    },
    {
      name: 'GPSNR',
      data: {
        deadline: '05-25-2023',
      },
      tabs: [
        {
          name: 'RR Submission Dashboard',
        },
        {
          name: 'Results Overview',
        },
      ],
    },
  ];

  loading$: Observable<boolean>;

  constructor(
    private questionnaireStateService: QuestionnaireStateService,
    private documentStateService: DocumentStateService,
    private authService: AuthService,
    private router: ActivatedRoute,
    private emailPayloadService: EmailPayloadService
  ) {}

  ngOnInit(): void {
    this.initializeTokens();
  }

  initializeTokens() {
    this.authService.userInfoToken = this.router.snapshot.params['token'];
    this.authService.getAdminFirebaseToken().subscribe((res: UserInfo) => {
      this.emailPayloadService.ownerCompanyName = res.company.legal_entity_name;
      this.emailPayloadService.senderEmail = res.contact.email;
      this.emailPayloadService.senderName = `${res.contact.first_name} ${res.contact.last_name}`;
      this.emailPayloadService.logoURI =
        res.company.company_logo_url ||
        'https://assets.agridence.com/logos/agd-logo.png';
      this.questionnaireStateService.getQuestionnaire(0, res.company.id);
      this.authService.firebaseToken = res.token;
    });

    this.questionnaireStateService.questionnaire$.subscribe((res) => {
      this.documentStateService.getDocuments(res.formId);
    });
  }
}
