import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { DocumentStateService } from 'src/app/services/state-service/documents-state.service';
import { QuestionnaireStateService } from 'src/app/services/state-service/questionnaire-state.service';
import { DownloadAttachmentModalComponent } from '../download-attachment-modal/download-attachment-modal.component';
import { saveAs } from 'file-saver';
import { BroadcastReminderModalComponent } from '../broadcast-reminder-modal/broadcast-reminder-modal.component';
import { FormService } from 'src/app/services/data-service/forms.service';
import { MessageService } from 'primeng/api';
import { RowData, SubmissionFile } from 'src/app/models/table-data.model';
import { DownloadReportModalComponent } from '../download-report-modal/download-report-modal.component';
import { DownloadService } from 'src/app/services/download.service';
import { TitleOptions } from 'src/app/models/title-options.model';
import { Status } from 'src/app/enums/status.enum';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-submission-table',
  templateUrl: './submission-table.component.html',
  styleUrls: ['./submission-table.component.scss'],
})
export class SubmissionTableComponent implements OnInit, OnDestroy {
  @ViewChild('dt') dt: Table;
  rowData: RowData[];
  titles: TitleOptions[];
  selectedTitle: TitleOptions;
  selectedSubmissions: RowData[];
  destroyed$: Subject<void> = new Subject<void>();
  showAttachmentDialog: boolean;
  statusOptions: string[] = [];
  globalFilterFields: string[] = [];
  status = Status;

  get isNoSelectedSubmission() {
    return this.selectedSubmissions?.length <= 0 || !this.selectedSubmissions;
  }

  columns = [
    {
      id: 1,
      field: 'recipientcompanyname',
      displayName: 'Company Name',
      sortable: true,
    },
    {
      id: 2,
      field: 'recipients',
      displayName: 'Email Address',
      sortable: true,
    },
    {
      id: 3,
      field: 'status',
      displayName: 'Status',
      filter: true,
    },
    {
      id: 4,
      field: 'modifiedOn',
      displayName: 'Last Modified',
      sortable: true,
    },
    {
      id: 5,
      field: 'dateSubmitted',
      displayName: 'Date Submitted',
      sortable: true,
    },
    {
      id: 6,
      field: 'submissionReport',
      displayName: 'Submission Report',
    },
    {
      id: 7,
      field: 'attachments',
      displayName: 'Attachments',
    },
  ];

  constructor(
    private documentStateService: DocumentStateService,
    private questionnaireStateService: QuestionnaireStateService,
    private dialogService: DialogService,
    private formService: FormService,
    private messageService: MessageService,
    private downloadService: DownloadService
  ) {}

  ngOnInit(): void {
    this.populateSubmissionTable();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  populateSubmissionTable() {
    this.documentStateService.documents$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        this.rowData = res.map((res) => {
          const updatedFiles = res.files.map((file) => ({
            ...file,
            isDownloadStarted: false,
            isDownloadCompleted: false,
            attachmentDownloadProgress: 0,
            reportDownloadProgress: 0,
          }));

          return {
            ...res,
            dateSubmitted: res.modifiedOn,
            reportDownloadApiCalled: false,
            files: updatedFiles,
          };
        });

        this.statusOptions = Array.from(
          new Set(this.rowData.map((obj) => obj.status))
        );
      });

    this.questionnaireStateService.questionnaires$
      .pipe(
        map((q) => {
          const titleList = q.map((ques, index) => ({
            label: ques.title,
            formId: ques.formId,
            index: index,
            title: ques.title.replace('Year ', ''),
          }));
          return titleList;
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe((res) => {
        this.titles = res;
      });

    this.globalFilterFields = this.columns.map((c) => c.field);
  }

  onTitleChanged() {
    this.selectedSubmissions = [];
    this.documentStateService.getDocuments(this.selectedTitle.formId);
    this.questionnaireStateService.getQuestionnaire(
      this.selectedTitle.index,
      this.questionnaireStateService.ownerOrgId
    );
  }

  onDownloadClicked() {
    const downloadDone$ = new Subject<void>();
    this.questionnaireStateService.questionnaire$
      .pipe(takeUntil(downloadDone$))
      .subscribe((res) => {
        this.dialogService.open(DownloadReportModalComponent, {
          data: res,
          width: '502px',
        });
        downloadDone$.next();
        downloadDone$.complete();
      });
  }

  onAttachmentClicked() {
    const filteredSubmissions = this.selectedSubmissions.filter(
      (submission) => submission.status === Status.Submitted
    );
    this.dialogService.open(DownloadAttachmentModalComponent, {
      data: filteredSubmissions,
      styleClass: 'attachment-modal',
      width: '700px',
    });
  }

  onBroadcastReminderClicked() {
    const filteredSubmissions = this.selectedSubmissions.filter(
      (submission) => submission.status !== Status.Submitted
    );
    this.dialogService.open(BroadcastReminderModalComponent, {
      data: filteredSubmissions,
      styleClass: 'broadcast-reminder-modal',
      width: '502px',
    });
  }

  applyFilterGlobal(event, filterType) {
    this.dt.filterGlobal((event.target as HTMLInputElement).value, filterType);
  }

  onRowDownloadAttachment(
    rowData: RowData,
    disabled: boolean,
    isClickable: boolean
  ) {
    if (!isClickable) return;

    if (!disabled) {
      rowData.attachmentDownloadProgress = 0;
      rowData.attachmentDownloadStarted = true;
      this.downloadService
        .downloadAsZip(rowData.files, rowData.recipientcompanyname)
        .subscribe({
          next: (res) => {
            rowData.attachmentDownloadProgress = res;
            if (res === 100) {
              rowData.attachmentDownloadStarted = false;
            }
          },
        });
    }
  }

  onRowViewReport(rowData: RowData, disabled: boolean, isClickable: boolean) {
    if (!isClickable) return;

    rowData.reportDownloadApiCalled = true;
    if (!disabled) {
      this.formService.downloadPdfSubmission(rowData.submissionid).subscribe({
        next: (res: Blob) => {
          if (!res) {
            this.messageService.add({
              severity: 'info',
              detail: 'No report available',
            });
            return;
          }
          saveAs(res, `${rowData.recipientcompanyname} Submission Report.pdf`);
        },
        error: (err) => {
          // eslint-disable-next-line no-console
          console.log(err);
          rowData.reportDownloadApiCalled = false;
        },
        complete: () => (rowData.reportDownloadApiCalled = false),
      });
    }
  }

  isValidSubmissionId(id: string) {
    return id !== 'tbp' || !id;
  }

  isFilesExist(files: SubmissionFile[]) {
    return files.length > 0;
  }
}
