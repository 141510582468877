<p-skeleton
  width="100%"
  height="30rem"
  *ngIf="!rowData?.length; else table"
></p-skeleton>
<ng-template #table>
  <div class="p-card table-container">
    <p-table
      #dt
      [value]="rowData"
      [paginator]="true"
      [rows]="10"
      [(selection)]="selectedSubmissions"
      [columns]="columns"
      [selectionPageOnly]="true"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 25, 50]"
      [globalFilterFields]="globalFilterFields"
    >
      <ng-template pTemplate="caption">
        <div class="grid p-3" style="border-bottom: 1px solid #b9b9b9">
          <div class="col-12 flex align-items-center justify-content-between">
            <p-dropdown
              class="table-title"
              (onChange)="onTitleChanged()"
              [(ngModel)]="selectedTitle"
              [options]="titles"
              optionLabel="label"
            ></p-dropdown>
            <div class="flex justify-content">
              <p-button
                (onClick)="onDownloadClicked()"
                class="action-icons download"
                ><i class="pi pi-download"></i
              ></p-button>
              <p-button
                class="action-icons"
                [disabled]="isNoSelectedSubmission"
                (onClick)="onAttachmentClicked()"
                styleClass="p-button-warning"
                ><i class="pi pi-paperclip"></i
              ></p-button>
              <p-button
                [disabled]="isNoSelectedSubmission"
                class="action-icons"
                (onClick)="onBroadcastReminderClicked()"
                styleClass="p-button-success"
                ><i class="pi pi-bell"></i
              ></p-button>
            </div>
          </div>
        </div>
        <div class="grid p-3 table-title">
          <div class="col-12 flex align-items-center justify-content-between">
            <span class="table-label">
              Submission for {{ selectedTitle?.title }}
            </span>
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input
                type="text"
                (input)="applyFilterGlobal($event, 'contains')"
                pInputText
                placeholder="Search"
              />
            </span>
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th class="checkbox-cell">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <ng-container *ngFor="let column of columns">
            <th [pSortableColumn]="column.sortable ? column.field : ''">
              {{ column.displayName }}
              <p-sortIcon
                *ngIf="column.sortable"
                [field]="column.field"
              ></p-sortIcon>
              <p-columnFilter
                *ngIf="column.filter"
                [field]="column.field"
                matchMode="equals"
                display="menu"
                [showMatchModes]="false"
                [showOperator]="false"
                [showAddButton]="false"
                [showApplyButton]="false"
              >
                <ng-template
                  pTemplate="filter"
                  let-value
                  let-filter="filterCallback"
                >
                  <p-dropdown
                    [ngModel]="value"
                    [options]="statusOptions"
                    placeholder="Select status"
                    (onChange)="filter($event.value)"
                    optionLabel="name"
                  >
                    <ng-template let-option pTemplate="item">
                      <div class="inline-block vertical-align-middle">
                        <span class="ml-1 mt-1">{{ option | titlecase }}</span>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </ng-template>
              </p-columnFilter>
            </th>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr>
          <td>
            <p-checkbox
              [value]="rowData"
              name="submissions"
              [(ngModel)]="selectedSubmissions"
            ></p-checkbox>
          </td>
          <ng-container *ngFor="let column of columns">
            <td
              [ngClass]="
                column.field === 'recipientcompanyname' ||
                column.field === 'recipients'
                  ? 'cell-max-width'
                  : ''
              "
            >
              <label
                *ngIf="
                  column.displayName === 'Date Submitted' ||
                    column.field === 'submissionReport' ||
                    column.field === 'attachments';
                  else otherFields
                "
              >
                <span *ngIf="column.field === 'dateSubmitted'">
                  {{
                    rowData["status"] === status.Submitted
                      ? (rowData[column.field] | date : "dd/MM/yyyy")
                      : "-"
                  }}
                </span>

                <span
                  (click)="
                    onRowDownloadAttachment(
                      rowData,
                      rowData['attachmentDownloadProgress'] !== 100 &&
                        rowData['attachmentDownloadStarted'],
                      rowData['status'] === status.Submitted &&
                        isFilesExist(rowData['files'])
                    )
                  "
                  [ngClass]="
                    rowData['attachmentDownloadStarted']
                      ? 'disabled-label'
                      : rowData['status'] === status.Submitted &&
                        isFilesExist(rowData['files'])
                      ? 'clickable-label'
                      : ''
                  "
                  *ngIf="column.field === 'attachments'"
                >
                  {{
                    rowData["status"] === status.Submitted &&
                    isFilesExist(rowData["files"])
                      ? "Download"
                      : "-"
                  }}
                  <p-progressSpinner
                    *ngIf="
                      rowData['attachmentDownloadProgress'] !== 100 &&
                      rowData['attachmentDownloadStarted']
                    "
                    styleClass="w-1rem h-1rem ml-1"
                    strokeWidth="8"
                  ></p-progressSpinner>
                </span>
                <span
                  (click)="
                    onRowViewReport(
                      rowData,
                      rowData['reportDownloadApiCalled'],
                      rowData['status'] === status.Submitted &&
                        isValidSubmissionId(rowData['submissionid'])
                    )
                  "
                  [ngClass]="
                    rowData['reportDownloadApiCalled']
                      ? 'disabled-label'
                      : rowData['status'] === status.Submitted &&
                        isValidSubmissionId(rowData['submissionid'])
                      ? 'clickable-label'
                      : ''
                  "
                  *ngIf="column.field === 'submissionReport'"
                >
                  {{
                    rowData["status"] === status.Submitted &&
                    isValidSubmissionId(rowData["submissionid"])
                      ? "View Report"
                      : "-"
                  }}
                  <p-progressSpinner
                    *ngIf="rowData['reportDownloadApiCalled']"
                    styleClass="w-1rem h-1rem ml-1"
                    strokeWidth="8"
                  ></p-progressSpinner>
                </span>
              </label>
              <label *ngIf="column.field === 'status'">
                {{ rowData[column.field] | titlecase }}
              </label>
              <label *ngIf="column.field === 'recipients'">
                <ng-container
                  *ngFor="let recipient of rowData[column.field]; last as last"
                >
                  {{ recipient }}<span *ngIf="!last">,</span> <br />
                </ng-container>
              </label>
              <label *ngIf="column.field === 'modifiedOn'">
                {{ rowData[column.field] | date : "dd/MM/yyyy" }}
              </label>
              <ng-template #otherFields>
                <label
                  *ngIf="
                    column.field !== 'status' &&
                    column.field !== 'recipients' &&
                    column.field !== 'modifiedOn'
                  "
                >
                  {{ rowData[column.field] }}
                </label>
              </ng-template>
            </td>
          </ng-container>
        </tr>
      </ng-template>
    </p-table>
  </div>
</ng-template>

<p-toast></p-toast>
