import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { FormService } from 'src/app/services/data-service/forms.service';
import { saveAs } from 'file-saver';
import { Questionnaire } from 'src/app/interface/questionnaire-state.interface';
@Component({
  selector: 'app-download-report-modal',
  templateUrl: './download-report-modal.component.html',
  styleUrls: ['./download-report-modal.component.scss'],
})
export class DownloadReportModalComponent implements OnInit {
  destroyed$: Subject<void> = new Subject<void>();
  questionnaire: Questionnaire;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  constructor(
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private formService: FormService
  ) {}

  ngOnInit(): void {
    this.initializeModalProperties();
    this.downloadExcelReport();
  }

  initializeModalProperties() {
    this.questionnaire = this.dialogConfig.data;
    this.dialogConfig.closable = false;
    this.dialogConfig.closeOnEscape = false;
  }

  downloadExcelReport() {
    this.loading$.next(true);
    this.formService
      .downloadExcelReport(this.questionnaire?.formId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          saveAs(res, `${this.questionnaire.title} report`);
        },
        error: (err) => {
          console.log(err);
        },
        complete: () => this.loading$.next(false),
      });
  }

  onClose() {
    this.dialogRef.close();
  }
}
