import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SustainabilityFormsAdminShellComponent } from './sustainability-forms-admin-shell/sustainability-forms-admin-shell.component';
import { PrimengExportsModule } from './primeng-exports.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GpnsrTabContentComponent } from './gpnsr-tab-content/gpnsr-tab-content.component';
import { RrSubmissionDashboardComponent } from './gpnsr-tab-content/rr-submission-dashboard/rr-submission-dashboard.component';
import { NgChartsModule } from 'ng2-charts';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { environment } from 'src/environments/environment';
import { SubmissionTableComponent } from './gpnsr-tab-content/submission-table/submission-table.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormService } from './services/data-service/forms.service';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ArpFormsInterceptor } from './interceptor/arp-forms.interceptor';
import { DownloadAttachmentModalComponent } from './gpnsr-tab-content/download-attachment-modal/download-attachment-modal.component';
import { FileSizePipe } from './pipes/file-size.pipe';
import { BroadcastReminderModalComponent } from './gpnsr-tab-content/broadcast-reminder-modal/broadcast-reminder-modal.component';
import { ResultsOverviewComponent } from './gpnsr-tab-content/results-overview/results-overview.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { DownloadReportModalComponent } from './gpnsr-tab-content/download-report-modal/download-report-modal.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { NoRecordsPageComponent } from './no-records-page/no-records-page.component';

@NgModule({
  declarations: [
    AppComponent,
    SustainabilityFormsAdminShellComponent,
    GpnsrTabContentComponent,
    RrSubmissionDashboardComponent,
    SubmissionTableComponent,
    ErrorPageComponent,
    DownloadAttachmentModalComponent,
    FileSizePipe,
    BroadcastReminderModalComponent,
    ResultsOverviewComponent,
    DownloadReportModalComponent,
    NoRecordsPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    PrimengExportsModule,
    BrowserAnimationsModule,
    NgChartsModule,
    NgxExtendedPdfViewerModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence(),
    AngularFireStorageModule,

    provideFirestore(() => getFirestore()),
  ],
  providers: [
    FormService,
    DatePipe,
    TitleCasePipe,
    DynamicDialogConfig,
    DynamicDialogRef,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ArpFormsInterceptor,
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
