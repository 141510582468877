<div class="grid flex-column">
  <div class="col flex justify-content-center header">Download Attachments</div>
  <div class="col">
    <div class="grid" *ngIf="selectedFiles.length > 0">
      <div class="pb-0 col-12 file-count">
        {{ selectedFiles.length }} file<ng-container
          *ngIf="selectedFiles.length > 1"
          >s</ng-container
        >
      </div>
    </div>
  </div>
  <div class="col">
    <div class="grid p-0 files-table">
      <div class="col-12 pt-0">
        <p-table
          [value]="files"
          [columns]="columns"
          [(selection)]="selectedFiles"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>
                <p-tableHeaderCheckbox
                  [disabled]="!isDownloadComplete && isDownloadStarted"
                ></p-tableHeaderCheckbox>
              </th>
              <ng-container *ngFor="let column of columns">
                <th>
                  {{ column.displayName }}
                </th>
              </ng-container>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-file>
            <tr>
              <td>
                <p-checkbox
                  [disabled]="!isDownloadComplete && isDownloadStarted"
                  name="files"
                  [value]="file"
                  [(ngModel)]="selectedFiles"
                ></p-checkbox>
              </td>
              <td class="file-name">{{ file.Name }}</td>
              <td>{{ file.companyName }}</td>
              <td style="text-align: left">{{ file.Size | fileSize }}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
    <div class="col pb-0">
      <div class="grid my-3">
        <div class="col flex justify-content-end">
          <p-button
            class="mx-3"
            styleClass="p-button-outlined"
            (onClick)="onClose()"
            [disabled]="!isDownloadComplete && isDownloadStarted"
            >Cancel</p-button
          >
          <div class="grid flex-column">
            <div class="col">
              <p-button
                [disabled]="
                  (!isDownloadComplete && isDownloadStarted) ||
                  !selectedFiles.length
                "
                (onClick)="onDownload()"
                ><i class="pi pi-download mr-2"></i>Download</p-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 p-0" *ngIf="isDownloadStarted">
      <p-progressBar [value]="downloadProgress"></p-progressBar>
    </div>
  </div>
</div>
