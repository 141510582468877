import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ErrorPageComponent } from '../error-page/error-page.component';

@Injectable()
export class ArpFormsInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
    private dialogService: DialogService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const authToken = request.url.includes('admin/token/')
      ? `Token ${this.authService.userInfoToken}`
      : `Bearer ${this.authService.firebaseToken}`;
    const modifiedRequest = request.clone({
      setHeaders: {
        Authorization: authToken,
      },
    });
    return next.handle(modifiedRequest).pipe(
      catchError((error: HttpErrorResponse) => {

        if (
          error.status === 401 ||
          (error.status === 404 && error.message === 'Not found')
        ) {
          this.router.navigate(['error'], {
            queryParams: { status: error.status, detail: error.error.detail },
          });
        } else {
          this.dialogService.open(ErrorPageComponent, {
            data: error,
            width: '502px',
          });
        }

        return throwError(() => error.error.detail);
      })
    );
  }
}
