<ng-container *ngIf="!isNoResults; else noResults">
  <div class="flex justify-content-between">
    <div>
      <p-dropdown
        class="results-overview-dropdown mb-2"
        (onChange)="onResultsOverviewChanged($event)"
        [(ngModel)]="selectedResults"
        [options]="results"
        optionLabel="title"
      ></p-dropdown>
    </div>

    <div>
      <i
        class="pi pi-refresh refresh mr-2"
        [@rotateIcon]="isRefreshIconRotated ? 'rotated' : 'default'"
        (click)="refresh()"
      ></i>
      <span
        >Last updated on {{ lastUpdated | date : "dd/MM/yyyy" }} |
        {{ lastUpdated | date : "hh:mm:ss a" }}</span
      >
    </div>
  </div>
  <ngx-extended-pdf-viewer
    *ngIf="pdfSource"
    [showRotateButton]="false"
    [showOpenFileButton]="false"
    [showTextEditor]="false"
    [showDrawEditor]="false"
    [src]="pdfSource"
    height="80vh"
  >
  </ngx-extended-pdf-viewer>
  <div class="flex justify-content-center" *ngIf="loadingPdfReportUrl">
    <p-progressSpinner></p-progressSpinner>
  </div>
</ng-container>

<ng-template #noResults>
  <app-no-records-page></app-no-records-page>
</ng-template>
