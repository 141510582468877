import { Component } from '@angular/core';

@Component({
  selector: 'app-no-records-page',
  templateUrl: './no-records-page.component.html',
  styleUrls: ['./no-records-page.component.scss']
})
export class NoRecordsPageComponent {

}
