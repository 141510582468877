import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userInfoToken: string;
  firebaseToken: string;

  constructor(private httpClient: HttpClient) {}

  getAdminFirebaseToken() {
    return this.httpClient.get(`${environment.ARP_FORMS}admin/token/`);
  }
}
