export class EmailPayload {
  emails?: string[];
  owner_company_name?: string;
  sender_email?: string;
  sender_name?: string;
  body?: string;
  documentIds: string[];
  form_name?: string;
  form_deadline?: string;
  form_id?: string;
  subject?: string;
  logo_uri?: string;
}
