import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Chart, Plugin } from 'chart.js';
import {
  BehaviorSubject,
  Observable,
  Subject,
  interval,
  takeUntil,
} from 'rxjs';
import { DocumentStateService } from 'src/app/services/state-service/documents-state.service';
import { QuestionnaireStateService } from 'src/app/services/state-service/questionnaire-state.service';
import {
  INITIAL_QUESTIONNAIRE_STATE,
  Questionnaire,
} from 'src/app/interface/questionnaire-state.interface';
import { UIChart } from 'primeng/chart';
import { Status } from 'src/app/enums/status.enum';

@Component({
  selector: 'app-rr-submission-dashboard',
  templateUrl: './rr-submission-dashboard.component.html',
  styleUrls: ['./rr-submission-dashboard.component.scss'],
})
export class RrSubmissionDashboardComponent implements OnInit, OnDestroy {
  @ViewChild('chart') chart: UIChart;
  totalSurveyCount$: Subject<number[]> = new Subject<number[]>();
  submittedCount: number;
  notSubmittedCount: number;
  totalSurveys = {
    labels: [Status.Submitted, Status.NotSubmitted],
    datasets: [
      {
        data: [],
        backgroundColor: ['#348A3B', '#F16E6A'],
      },
    ],
  };
  options = {
    cutout: '85%',
    responsive: false,
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
    },
  };

  deadlineDate: Date;
  isDeadlineEnded = false;
  daysOver = 0;
  remainingHours: string;
  remainingMinutes: string;
  remainingSeconds: string;
  remainingDays: string;
  notSubmittedMessage = 'suppliers have not submitted yet';
  notSubmitted: number;

  questionnaire$: Observable<Questionnaire>;
  deadlineLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  chartLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  test = false;

  destroyed$: Subject<void> = new Subject<void>();

  get exceedDeadlineMessage() {
    return `Exceed the submission deadline by over ${this.daysOver} day${
      this.daysOver > 1 ? 's' : ''
    }`;
  }

  get deadlineHeader() {
    return this.isDeadlineEnded ? 'Submission Deadline Ended on:' : 'Deadline';
  }

  constructor(
    private questionnaireStateService: QuestionnaireStateService,
    private documentStateService: DocumentStateService
  ) {}

  ngOnInit() {
    this.questionnaire$ = this.questionnaireStateService.questionnaire$;
    this.getSubmissions();
    this.getDeadline();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  getValuePercentage(value, dataset: any[]) {
    const parsedValue = Number(value);
    const total = dataset.reduce((sum, value) => sum + value, 0);

    return `${((parsedValue / total) * 100).toFixed(2)}%`;
  }

  getSubmissions() {
    this.chartLoading$.next(true);
    this.documentStateService.documents$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        if (res.length) {
          const submittedCount = res.filter(
            (item) => item.status === Status.Submitted
          ).length;

          this.notSubmitted = res.length - submittedCount;
          this.totalSurveyCount$.next([submittedCount, this.notSubmitted]);
          this.chartLoading$.next(false);
        }
      });

    this.totalSurveyCount$.subscribe((count) => {
      this.totalSurveys.datasets[0].data = [...count];
    });
  }

  getDeadline() {
    this.deadlineLoading$.next(true);
    this.questionnaire$.pipe(takeUntil(this.destroyed$)).subscribe((res) => {
      if (res !== INITIAL_QUESTIONNAIRE_STATE.questionnaire) {
        this.deadlineDate = new Date(res.end);
        this.initiateCountdown();
        this.deadlineLoading$.next(false);
      }
    });
  }

  initiateCountdown() {
    this.calculateRemainingTime();
    interval(1000)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.calculateRemainingTime();
      });
  }

  calculateRemainingTime() {
    const targetDateTime = new Date(this.deadlineDate).getTime();
    const currentTime = Date.now();
    const remainingTime = targetDateTime - currentTime;

    const oneMinuteInMs = 1000 * 60;
    const oneHourInMs = 1000 * 60 * 60;
    const oneDayInMs = 1000 * 60 * 60 * 24;

    this.isDeadlineEnded = remainingTime <= 0;
    if (this.isDeadlineEnded) {
      this.daysOver =
        Math.abs(Math.floor(remainingTime / (1000 * 60 * 60 * 24))) - 1;
    }
    // this.remainingSeconds = this.padNumber(
    //   Math.floor(remainingTime / 1000) % 60
    // );
    this.remainingMinutes = this.padNumber(
      Math.floor((remainingTime / oneMinuteInMs) % 60)
    );
    this.remainingHours = this.padNumber(
      Math.floor((remainingTime / oneHourInMs) % 24)
    );
    this.remainingDays = this.padNumber(Math.floor(remainingTime / oneDayInMs));
  }

  private padNumber(number: number): string {
    return number.toString().padStart(2, '0');
  }

  totalValuePlugin(): Plugin[] {
    return [
      {
        id: 'custom_doughnut_chart_center_display',
        afterDraw: (chart: Chart) => {
          const ctx = chart.ctx;
          const value = chart.data.datasets[0].data.reduce(
            (accumulator: number, currentValue: number) =>
              accumulator + currentValue
          ) as number;
          const fontSize = Math.min(chart.width / 8, chart.height / 8);

          const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
          const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;

          ctx.font = `${fontSize}px Nunito Sans`;
          ctx.fillStyle = 'black';
          ctx.textAlign = 'center';

          const lineHeight = fontSize * 0.65;
          const totalHeight = lineHeight * 2.5;
          const valueX = centerX;
          const valueY = centerY - totalHeight / 2 + lineHeight;
          const chartLabelX = centerX;
          const chartLabelY = centerY + totalHeight / 2;
          const totalFontSize = fontSize * 0.6;

          ctx.font = `${fontSize}px Nunito Sans`;
          ctx.fillText(value.toString(), valueX, valueY);
          ctx.font = `${totalFontSize}px Nunito Sans`;
          ctx.fillText('Total', chartLabelX, chartLabelY);
        },
      },
    ];
  }
}
