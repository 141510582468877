import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { EMPTY, map, switchMap } from 'rxjs';
import { FormService } from 'src/app/services/data-service/forms.service';
import { QuestionnaireStateService } from 'src/app/services/state-service/questionnaire-state.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-results-overview',
  templateUrl: './results-overview.component.html',
  styleUrls: ['./results-overview.component.scss'],
  animations: [
    trigger('rotateIcon', [
      state('default', style({ transform: 'rotate(0deg)' })),
      state('rotated', style({ transform: 'rotate(360deg)' })),
      transition('default <=> rotated', animate('300ms ease-in-out')),
    ]),
  ],
})
export class ResultsOverviewComponent implements OnInit {
  loadingPdfReportUrl: boolean = false;
  pdfSource: string = '';
  isNoResults: boolean = false;
  selectedResults: any;
  results: any;
  isRefreshIconRotated: boolean = false;
  lastUpdated: Date = new Date();

  constructor(
    private formService: FormService,
    private questionnaireStateService: QuestionnaireStateService,
    private utilityService: UtilityService
  ) {}

  ngOnInit(): void {
    this.getPdfReportUrl();
    this.initializeResultsList();
  }

  initializeResultsList() {
    this.questionnaireStateService.questionnaires$
      .pipe(
        map((q) => {
          const resultsList = q.map((ques, i) => ({
            index: i++,
            formId: ques.formId,
            reportId: this.utilityService.getReportId(ques.reports),
            title: ques.title,
          }));

          return resultsList;
        })
      )
      .subscribe((results) => {
        this.results = results;
      });
  }

  refresh() {
    this.isRefreshIconRotated = !this.isRefreshIconRotated;
    this.getPdfReportUrl(this.selectedResults.index);
  }

  onResultsOverviewChanged(event: any) {
    const value = event.value;
    this.getPdfReportUrl(value.index);
  }

  getPdfReportUrl(index: number = 0) {
    this.loadingPdfReportUrl = true;
    this.questionnaireStateService.questionnaires$
      .pipe(
        switchMap((res) => {
          const reportId = this.utilityService.getReportId(res[index]?.reports);
          if (!reportId) {
            this.isNoResults = true;
            return EMPTY;
          }

          return this.formService.getPdfReportUrl(res[index].formId, reportId);
        })
      )
      .subscribe({
        next: (res: { url: string; modified: string }) => {
          this.lastUpdated = new Date(res.modified);
          this.pdfSource = res.url;
          this.loadingPdfReportUrl = false;
        },
        // complete: () => (this.loadingPdfReportUrl = false),
      });
  }
}
